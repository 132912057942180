'use strict';

(function () {
  var self;
  class AddEditSiteModalCtrl {
    constructor($scope, $state, $uibModalInstance, Site, Organization, options, usSpinnerService, Util, Auth, MapService, NgMap, ActivityLog) {
      this.Site = Site;
      this.Util = Util;
      this.Organization = Organization;
      this.isAdmin = Auth.isAdmin;
      this.MapService = MapService;
      this.usSpinnerService = usSpinnerService;
      this.$uibModalInstance = $uibModalInstance;
      this.isDisabled = false;
      this.errors = {};
      this.options = options;
      this.submitted = false;
      this.ActivityLog = ActivityLog;

      this.mapZoom = 17;
      self = this;

      if (this.options.site) {
        this.site = angular.copy(this.options.site);
        this.address = Util.getAddress(this.site.location);
        this.mode = 'UPDATE';
        this.mapCenter = this.address;
        // try to search address in map
        if (!this.site.location || !this.site.location.googlePlaceId) {
          this.findPlaceFromAddress(this.address);
        }
        this.site.organization = this.options.site.organization._id;
      } else {
        this.mapCenter = 'UK';
        this.site = {
          name: null,
          location: {}
        };
        this.mode = 'CREATE';
      }
      if(this.options.organization) {
        this.organization = angular.copy(this.options.organization);
      }

      NgMap.getMap().then(mapObj => {
        // console.log('ng map init: ', map);
      });

      Auth.isLoggedInAsync((loggedIn) => {
        if (loggedIn) {
          this.currentUser = Auth.getCurrentUser();
          this.organizations = [];

          this.Organization.get().$promise
            .then(response=> {
              if (response.data && response.data.length) {
                self.organizations = response.data.sort((a,b) => a.name.localeCompare(b.name));
                self.site.organization = _.find(self.organizations, { _id: self.site.organization });
              }
            });
        } else {
          $state.go('login');
        }
      });

      $scope.$on('$stateChangeStart', function () {
        $uibModalInstance.dismiss();
      });
    }

    placeChanged() {
      self.place = this.getPlace();
      // console.log('location', self.place.address_components);
      self.setPlace();
    }

    setPlace(fromAddressSearch) {
      // console.log('in set place: ', this.place);
      this.site.location = {
        googlePlaceId: this.place.place_id,
        addressLine1: this.place.address_components[0].long_name,
        addressLine2: this.place.address_components[1] ? this.place.address_components[1].long_name : '',
        addressLine3: this.place.address_components[2] ? this.place.address_components[2].long_name : '',
        addressLine4: this.place.address_components[3] ? this.place.address_components[3].long_name : '',
      };
      let newLat, newLng;
      if(fromAddressSearch) {
        newLat = this.place.geometry.location.lat;
        newLng = this.place.geometry.location.lng;
      } else {
        newLat = this.place.geometry.location.lat();
        newLng = this.place.geometry.location.lng();
      }
      this.site.location.position = [newLng, newLat];

      try {
        this.site.location.country = _.find(this.place.address_components, place => place.types.indexOf('country') > -1).long_name;
        this.site.location.addressLine5 = _.find(this.place.address_components, place => place.types.indexOf('postal_code') > -1).long_name;
      } catch (err) {
        console.log('postal code missing: ');
      }
      console.log('new site location: ', this.site.location);
      this.mapCenter = `${newLat},${newLng}`;
      this.mapZoom = 17;
    }

    mapCenterChanged() {
      // console.log('center change: ', this.mapCenter);
      console.log( this.getCenter().lat());
      const newCenter = `${this.getCenter().lat()},${this.getCenter().lng()}`;
      self.findPlaceFromLatLng(newCenter);
    }

    findPlaceFromAddress(address) {
      this.isLoading = true;
      this.MapService.getGooglePlaceFromText(address).then(result => {
        this.isLoading = false;
        // this.stopSpin('spinner-1');
        this.place = result.data.result;
        if (this.place) {
          this.address = this.place.formatted_address;
        }
        this.setPlace(true);
      }).catch(err => {
        this.isLoading = false;
        this.errors = {
          address: 'Could not find matching valid address automatically, select valid address manually from google places search.'
        };
        console.log('find place err: ', err);
      });
    }

    findPlaceFromLatLng(latlng) {
      this.isLoading = true;
      this.MapService.getGooglePlaceFromLatLng(latlng).then(result => {
        this.isLoading = false;
        console.log('place from lat/lng result: ', result);
        // this.stopSpin('spinner-1');
        this.place = result.data;
        if (this.place) {
          this.address = this.place.formatted_address;
        }
        this.errors = null;
        this.setPlace(true);
      }).catch(err => {
        this.isLoading = false;
        this.errors = {
          address: `Could not find matching 'address' from selected marker position.`
        };
        console.log('find place err: ', err);
      });
    }

    saveSite(form) {
      this.submitted = true;
      this.serverError = null;
      if (!this.site.location || !this.site.location.googlePlaceId) {
        this.errors = {
          address: 'Select valid location from autocomplete'
        };
        return;
      }
      // console.log('save site data: ', this.site);
      if (form.$valid) {
        this.isDisabled = true;
        this.startSpin('spinner-1');
        if(this.organization && this.organization._id) {
          this.site.organization = this.organization._id;
        }
        if(this.site.organization._id) {
          this.site.organization = this.site.organization._id;
        }
        if (this.mode === 'CREATE') {
          this.Site.save(this.site).$promise
            .then(site => {
              this.stopSpin('spinner-1');
              console.log(site);
              const activityLogData = {
                site: site.data._id,
                user: this.currentUser._id,
                source: 'web',
                action: 'create',
                type: 'site',
                sourceScreen: 'dashboard',
                eventString: `Wind farm <a href="/site/${site.data._id}">${site.data.name}</a> was created by ${this.currentUser.name}`
              }

              this.ActivityLog.save(activityLogData).$promise
                .then(activityLog => {
                  console.log(activityLog);
                })
                .catch(err => {
                  console.log('Error inserting activityLog', err);
              });

              this.$uibModalInstance.close(site);
            })
            .catch(err => {
              this.isDisabled = false;
              this.errors = {};
              this.stopSpin('spinner-1');
              this.handleError(err, form);
            });
        } else {
          this.Site.update(this.site).$promise
            .then(site => {
              this.stopSpin('spinner-1');
              console.log(site);
              const activityLogData = {
                site: site.data._id,
                user: this.currentUser._id,
                source: 'web',
                action: 'edit',
                type: 'site',
                sourceScreen: 'site',
                eventString: `Wind farm <a href="/site/${site.data._id}">${site.data.name}</a> was edited by ${this.currentUser.name}`
              }

              this.ActivityLog.save(activityLogData).$promise
                .then(activityLog => {
                  console.log(activityLog);
                })
                .catch(err => {
                  console.log('Error inserting activityLog', err);
              });
              this.$uibModalInstance.close(site);
            })
            .catch(err => {
              this.isDisabled = false;
              this.errors = {};
              this.stopSpin('spinner-1');
              this.handleError(err, form);
            });
        }
      }
    }

    handleError(err, form) {
      if (err && err.data && err.data.meta && err.data.meta.error_message) {
        if (err.data.meta.code && err.data.meta.code != 422 && !this.Util.isArray(err.data.meta.error_message)) {
          this.serverError = err.data.meta.error_message || 'Internal Server Error';
        } else {
          err.data.meta.error_message.forEach(errorMessage => {
            for (let fieldName in errorMessage) {
              if (form[fieldName]) {
                form[fieldName].$setValidity('mongoose', false);
                this.errors[fieldName] = errorMessage[fieldName];
              } else if (fieldName == 'address') {
                this.errors[fieldName] = errorMessage[fieldName];
              }
            }
          })
        }
      } else {
        this.serverError = 'Internal Server Error';
        console.log('validation err: ', err);
      }
    }

    cancelSave() {
      this.$uibModalInstance.dismiss('cancel');
    }

    startSpin(spinner) {
      this.usSpinnerService.spin(spinner);
    }

    stopSpin(spinner) {
      this.usSpinnerService.stop(spinner);
    }
  }

  angular.module('windmanagerApp')
    .controller('AddEditSiteModalCtrl', AddEditSiteModalCtrl);
})();